// shipment table fields, docs: https://coreui.io/vue/docs/components/table.html
export const SHIPMENT_TABLE_FIELDS = Object.freeze([
	{
		key: 'order_code',
		label: 'Order ID',
		class: 'order-col th-mw-180',
		cellClass: 'td-nowrap',
	},
	{
		key: 'order_status',
		label: 'Order Status',
		class: 'order-status-col th-mw-150',
		cellClass: 'td-nowrap',
	},
	{
		key: 'warehouse_location',
		label: 'Warehouse',
		class: 'warehouse-location-col th-mw-100',
		cellClass: 'td-nowrap',
	},
	{
		key: 'shipment_code',
		label: 'Shipment ID',
		class: 'shipment-col th-mw-120',
		cellClass: 'shipment-cel',
	},
	{
		key: 'status',
		label: 'Status',
		class: 'status-col th-mw-150',
		cellClass: 'status-cel',
	},
	{
		key: 'tracking_number',
		label: 'Tracking number',
		class: 'tracking-number-col th-mw-180',
		cellClass: 'td-nowrap',
	},
	{
		key: 'created',
		label: 'Created date',
		class: 'created-col th-mw-150',
		cellClass: 'td-nowrap',
	},
	{
		key: 'customer_name',
		label: 'Customer',
		class: 'customer-col th-mw-180',
		cellClass: 'td-nowrap',
	},
	{
		key: 'phone_number',
		label: 'Phone No.',
		class: 'phone-number-col th-mw-180',
		cellClass: 'td-nowrap',
	},
	{
		key: 'email',
		label: 'Email',
		class: 'email-col th-mw-180',
		cellClass: 'td-nowrap',
	},
	{
		key: 'shipment_method',
		label: 'Shipping method',
		class: 'shipment_method-col th-mw-180',
		cellClass: 'td-nowrap',
	},
	{
		key: 'courier',
		label: 'Courier',
		class: 'courier-col th-mw-180',
		cellClass: 'td-nowrap',
	},
]);

// shipment status, following from be
export const SHIPMENT_STATUS = Object.freeze({
	WAITING_FOR_PAYMENT: 'waiting_for_payment',
	WAREHOUSE_SELECTING: 'warehouse_selecting',
	READY_TO_CREATE_SHIPMENT: 'ready_to_create_shipment',
	READY_TO_BOOK_LOGISTIC: 'ready_to_book_logistic',
	READY_TO_SHIP: 'ready_to_ship',
	READY_TO_PICK_UP: 'ready_to_pick_up',
	SHIPPED: 'shipped',
	DELIVERED: 'delivered',
	CANCELED: 'canceled',
	BOOKING_FAILED: 'booking_failed',
	PICKED_UP: 'picked_up',
	SHIPPING_FAILED: 'shipping_failed',
});

// shipment staus option, use with dropdown ui
export const SHIPMENT_STATUS_OPTIONS = [
	{
		name: 'All shipment status',
		value: null,
	},
	{
		name: 'Waiting for payment',
		value: SHIPMENT_STATUS.WAITING_FOR_PAYMENT,
	},
	{
		name: 'Warehouse selecting',
		value: SHIPMENT_STATUS.WAREHOUSE_SELECTING,
	},
	{
		name: 'Ready to create shipment',
		value: SHIPMENT_STATUS.READY_TO_CREATE_SHIPMENT,
	},
	{
		name: 'Ready to book courier',
		value: SHIPMENT_STATUS.READY_TO_BOOK_LOGISTIC,
	},
	{
		name: 'Ready to ship',
		value: SHIPMENT_STATUS.READY_TO_SHIP,
	},
	{
		name: 'Shipped',
		value: SHIPMENT_STATUS.SHIPPED,
	},
	{
		name: 'Delivered',
		value: SHIPMENT_STATUS.DELIVERED,
	},
	{
		name: 'Canceled',
		value: SHIPMENT_STATUS.CANCELED,
	},
	{
		name: 'Booking failed',
		value: SHIPMENT_STATUS.BOOKING_FAILED,
	},
	{
		name: 'Shipping failed',
		value: SHIPMENT_STATUS.SHIPPING_FAILED,
	},
];


// mapping order color, use with CBadge property components
export const SHIPMENT_COLOR = {
	SHIPMENT: {
		[SHIPMENT_STATUS.WAITING_FOR_PAYMENT]: 'alto',
		[SHIPMENT_STATUS.WAREHOUSE_SELECTING]: 'orange',
		[SHIPMENT_STATUS.READY_TO_CREATE_SHIPMENT]: 'orange',
		[SHIPMENT_STATUS.READY_TO_BOOK_LOGISTIC]: 'orange',
		[SHIPMENT_STATUS.READY_TO_SHIP]: 'orange',
		[SHIPMENT_STATUS.SHIPPED]: 'orange',
		[SHIPMENT_STATUS.DELIVERED]: 'success',
		[SHIPMENT_STATUS.CANCELED]: 'alto',
		[SHIPMENT_STATUS.BOOKING_FAILED]: 'danger',
		[SHIPMENT_STATUS.SHIPPING_FAILED]: 'danger',
	},
};


// mapping shipment title, use with CBadge component
export const SHIPMENT_TITLE = {
	SHIPMENT: {
		[SHIPMENT_STATUS.WAITING_FOR_PAYMENT]: 'Waiting for payment',
		[SHIPMENT_STATUS.WAREHOUSE_SELECTING]: 'Warehouse selecting',
		[SHIPMENT_STATUS.READY_TO_CREATE_SHIPMENT]: 'Ready to create shipment',
		[SHIPMENT_STATUS.READY_TO_BOOK_LOGISTIC]: 'Ready to book courier',
		[SHIPMENT_STATUS.READY_TO_SHIP]: 'Ready to ship',
		[SHIPMENT_STATUS.SHIPPED]: 'Shipped',
		[SHIPMENT_STATUS.DELIVERED]: 'Delivered',
		[SHIPMENT_STATUS.CANCELED]: 'Canceled',
		[SHIPMENT_STATUS.BOOKING_FAILED]: 'Booking failed',
		[SHIPMENT_STATUS.SHIPPING_FAILED]: 'Shipping failed',
	},
};


// Detail page
export const SHIPMENT_DETAIL_TABLE_FIELDS = Object.freeze([
	{
		key: 'product',
		label: 'Product',
		class: 'product-col',
		cellClass: 'product-cel',
	},
	{
		key: 'quantity',
		label: 'Qty Packed',
		class: 'total-col',
		cellClass: 'total-cel',
	},
]);

export const COURIER_SLUG = {
	KERRY: 'kerry',
	DHL: 'dhl',
	SKOOTAR: 'skootar',
	LALAMOVE_TRUCK: 'lalamove-truck',
	LALAMOVE_MPV: 'lalamove-mpv',
	LALAMOVE_MOTORCYCLE: 'lalamove-motorcycle',
	THUNDERMOVE: 'thundermove',
};

export const COURIER = {
	[COURIER_SLUG.KERRY]: 'Kerry',
	[COURIER_SLUG.DHL]: 'DHL',
	[COURIER_SLUG.SKOOTAR]: 'Skootar',
	[COURIER_SLUG.LALAMOVE_TRUCK]: 'Lalamove | Truck',
	[COURIER_SLUG.LALAMOVE_MPV]: 'Lalamove | MPV',
	[COURIER_SLUG.LALAMOVE_MOTORCYCLE]: 'Lalamove | Motorcycle',
	[COURIER_SLUG.THUNDERMOVE]: 'Thundermove',
};

export const COURIER_OPTIONS = [
	{ name: COURIER[COURIER_SLUG.DHL], value: COURIER_SLUG.DHL },
	{ name: COURIER[COURIER_SLUG.SKOOTAR], value: COURIER_SLUG.SKOOTAR },
	{ name: COURIER[COURIER_SLUG.KERRY], value: COURIER_SLUG.KERRY },
	{ name: COURIER[COURIER_SLUG.LALAMOVE_MOTORCYCLE], value: COURIER_SLUG.LALAMOVE_MOTORCYCLE },
	{ name: COURIER[COURIER_SLUG.LALAMOVE_MPV], value: COURIER_SLUG.LALAMOVE_MPV },
	{ name: COURIER[COURIER_SLUG.LALAMOVE_TRUCK], value: COURIER_SLUG.LALAMOVE_TRUCK },
	{ name: COURIER[COURIER_SLUG.THUNDERMOVE], value: COURIER_SLUG.THUNDERMOVE },
	// TODO: TBD
	// { name: 'BNN (For pick up at store)', value: '' },
];

export const DEFAULT_COURIER = { name: COURIER[COURIER_SLUG.DHL], value: COURIER_SLUG.DHL };
